'use client'; // Error components must be Client Components

import { Button } from '@/components/ui/button';
import { datadogRum } from '@datadog/browser-rum';
import { useEffect } from 'react';

export default function Error({
  error,
  reset,
}: {
  error: Error & { digest?: string };
  reset: () => void;
}) {
  useEffect(() => {
    datadogRum.addError(error);
  }, [error]);

  return (
    <div>
      <div className='flex h-screen w-full flex-col items-center justify-center gap-2'>
        <h1>Något gick fel</h1>
        <Button variant={'outline'} onClick={() => reset()}>
          Försök igen
        </Button>
      </div>
    </div>
  );
}
